export const ChargeConstantCollection = ({
    dtlSate: {
        C:"C",
        DI:"DI",
        E:"E",
        F:"F",
        H:"H",
        I:"I",
        MH:"MH",
        MT:"MT",
        R:"R",
        SA:"SA",
        SG:"SG",
        SH:"SH",        
        T:"T",
        X:"X",
        Z:"Z"
    },
    hdrState: {
        CFM:"CFM",
        NIL:"NIL",
        INV:"INV",
        TML:"TML"
    },    
    manHdrState: {
        CFM:"CFM",
        NIL:"NIL",
        INV:"INV",
        TML:"TML",
        STD:"STD",
    },
    operationType: {
        D1:"D1",
        MG:"MG",
        L2:"L2",
    },
    emptyFullInd: {
        F:"F",
        E:"E",
    },
    bundleInd: {
        M:"M",
        S:"S",
    },
    billingType: {
        1:"1",
        2:"2",
        3:"3",
    },
    modality: {
        D:"D",
        SD:"SD",
        SB:"SB",
        T:"T",
        R:"R",
        B:"B"
    },
    systemManualInd: {
        Manual:"MAN",
        System:"SYS",
    },
    chargeHeaderGroup: {
        SHIPBOUNDED:"Ship Bounded",
        SHIPBOUNDEDINPERIOD:"Ship Bounded In Period",
        DOMESTIC: 'Domestic',
    },
    billingCycle: {
        MONTHLY:"MONTHLY",
        WEEKLY:"WEEKLY",
        ONE_OFF:"ONE_OFF",
        QUARTERLY:"QUARTERLY",
        BI_WEEKLY:"BI_WEEKLY",
        YEARLY:"YEARLY"
    },
    vesselType: {
        D:"D",
        F:"F",
        L:"L",
        S:"S",
    },
    adjType: {
        CANCEL:"CANCEL",
        CANCEL_REBILL:"CANCEL_REBILL",
        ADJUSTMENT:"ADJUSTMENT",
        NORMAL:"NORMAL",
        RECAL:"RECAL",
        CANCEL_REBILL_TO_OTHER_COMPANY:"CANCEL_REBILL TO OTHER COMPANY",
    },
    adjTypeForSearch: {
        CANCEL:"CANCEL",
        CANCEL_REBILL:"CANCEL_REBILL",
        ADJUSTMENT:"ADJUSTMENT",
        NORMAL:"NORMAL",
        RECAL:"RECAL",
    },
    adjTypeNoRecal: {
        CANCEL:"CANCEL",
        CANCEL_REBILL:"CANCEL_REBILL",
        ADJUSTMENT:"ADJUSTMENT",
        NORMAL:"NORMAL",
    },
    cntrTspStatus: {
        I: "I",
        E: "E",
        T: "T",
        C: "C",
        R: "R"
    },
    rfTempUnit: {
        RF_TEMP_UNIT_C: "C",
        RF_TEMP_UNIT_F: "F",
    },
    emptyInd: {
        Y: "Y",
        N: "N",
    },
    cntrStatus: {
        EM: "EM",
        IF: "IF",       
        IM: "IM",       
        TF: "TF",       
        TM: "TM",       
        XF: "XF",       
        XI: "XI",       
        XM: "XM",       
        OF: "OF",       
        OM: "OM",       
        RE: "RE",       
        RM: "RM",       
        SH: "SH",       
        SM: "SM",
    },
    ignoreCntrStatusValid: {
        Y: "Y",
        N: "N",
    },
    manualTsLegChargeInd: {
        Y: "Y",
        N: "N",
    },
    deliveryStatus: {
        IN_YARD: 'In Yard',
        DELIVERED: 'Delivered',
    },
    companyCode: {
        COMPANY_CODE_2691: '2691',
        COMPANY_CODE_2726: '2726',
    },
    uom: {
        DAY: 'DAY',
        HOUR: 'HOUR',
        ITEM: 'ITEM',
        KG: 'KG',
        KL: 'KL',
        LIFT: 'LIFT',
        MINUTE: 'MINUTE',
        MOVE: 'MOVE',
        SHIFT: 'SHIFT',
        TEU: 'TEU',
        TON: 'TON',
        KM: 'KM',
        M2: 'M2',
        M3: 'M3',
        TRUCK: 'TRUCK',
        DOC: 'DOC',
        UNIT: 'UNIT',
    },    
    cokdType: {
        ITEM_SYSTEM: 'System',
        ITEM_MANUAL: 'Manual',
    },
    cokdSubType: {
        ITEM_CNTR_NO: 'CNTR_NO',
        ITEM_CNTR_OPS_LINE: 'CNTR_OPS_LINE',
        ITEM_GATE_IN: 'GATE_IN',
        ITEM_TSP_STATUS: 'TSP_STATUS',
        ITEM_OOG: 'OOG',
        ITEM_IMO: 'IMO',
        ITEM_MTY_FULL: 'MTY_FULL',
        ITEM_CNTR_SIZE: 'CNTR_SIZE',
        ITEM_GATE_OUT: "GATE_OUT"
    },
    directDischargeInd: {
        Direct: "D",
        Indirect: "I"
    },
    changeOfVslInd: {
        Y: "Y",
        N: "N",
    },
    commonAcType: {
        RATE: "RATE",
        QTY: "QTY",
    },
    ReeferHandlingType: {
        M: "M",
        T: "T",
        C: "C",
        F: "F",
    },
    directDischargeIndVal: {
        D: "D",
        I: "I"
    },
    rcType: {
        REHANDLING: "REHANDLING",
        CANCEL_SHIPMENT: "CANCEL_SHIPMENT"
    },
    discountType: {
        FIX: "FIX",
        PERCENT: "PERCENT"
    },
    correction: {
        FIX: "FIX",
        DISCHARGE: "DISCHARGE"
    },
});