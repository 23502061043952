import { ChargeTypeMaintenanceConstant } from "./ChargeTypeMaintenanceConstant";
const SCREEN_CONSTANT = ChargeTypeMaintenanceConstant; 
export const INITIAL_CHARGE_TYPE_COL_DEF: any[] = [ 
        { 
            headerName: SCREEN_CONSTANT.CHARGE_TYPE, 
            field: "chargeType", 
            enableRowGroup: false, 
            enablePivot: true, 
            enableValue: false, 
            rowGroup: false, 
            filter: false, 
            width: 150, 
            //pinned: "left", 
            //checkboxSelection: true, 
            //headerCheckboxSelection: true, 

        }, 
        { 
            headerName: SCREEN_CONSTANT.SUB_CHARGE_TYPE, 
            field: "subChargeType", 
            enableRowGroup: false, 
            enablePivot: true, 
            enableValue: false, 
            rowGroup: false, 
            filter: false, 
            width: 180, 
        }, 
        { 
            headerName: SCREEN_CONSTANT.DESC, 
            field: "chargeTypeDesc", 
            enableRowGroup: false, 
            enablePivot: true, 
            enableValue: false, 
            rowGroup: false, 
            filter: false, 
            width: 200, 
        }, 
        { 
            headerName: SCREEN_CONSTANT.TPUT_IND, 
            field: "tputStatisticInd", 
            enableRowGroup: false, 
            enablePivot: true, 
            enableValue: false, 
            rowGroup: false, 
            filter: false, 
            width: 230, 
        }, 
        { 
            headerName: SCREEN_CONSTANT.COUNT_FSP_IND, 
            field: "countFspInd", 
            enableRowGroup: false, 
            enablePivot: true, 
            enableValue: false, 
            rowGroup: false, 
            filter: false, 
            width: 160, 
        }, 
        { 
            headerName: SCREEN_CONSTANT.CATEGORY, 
            field: "chargeCategory", 
            enableRowGroup: false, 
            enablePivot: true, 
            enableValue: false, 
            rowGroup: false, 
            filter: false, 
            width: 100, 
        }, 
        { 
            headerName: SCREEN_CONSTANT.ACTIVE_IND, 
            field: "activeInd", 
            enableRowGroup: false, 
            enablePivot: true, 
            enableValue: false, 
            rowGroup: false, 
            filter: false, 
            width: 130, 
        }, 
] 
