import { SelectionChangedEvent } from "ag-grid-community";
import { ChargeTypeEntity } from "domain/entity/ChargeType/ChargeTypeEntity";
import { INITIAL_CHARGE_TYPE_COL_DEF } from "presentation/constant/ChargeType/ChargeTypeMaintenanceColumnDefinition";
import { ChargeTypeMaintenanceConstant } from "presentation/constant/ChargeType/ChargeTypeMaintenanceConstant";
import { useChargeTypeMaintenanceVM } from "presentation/hook/ChargeType/useChargeTypeMaintenanceVM";
import { useChargeTypeMaintenanceTracked } from "presentation/store/ChargeType/ChargeTypeMaintenanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";
const ChargeTypeTablePanel:React.FC = () => { 
    const [chargeTypeState] = useChargeTypeMaintenanceTracked(); 
    const chargeTypeVM = useChargeTypeMaintenanceVM(); 
    const gridRef: any = useRef(null); 
    const {tableData} = chargeTypeState; 
    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {      
        const selectedRows = e.api.getSelectedRows(); 
        chargeTypeVM.updateSelectedRows(tableData,selectedRows); 
    }, [chargeTypeVM, tableData]) 
    const handleAddClick = useCallback(() => { 
        chargeTypeVM.onAdd(); 
    }, [chargeTypeVM]) 
    const handleRowDoubleClick = useCallback((entity: ChargeTypeEntity) => { 
          chargeTypeVM.onEdit(entity); 
    }, [chargeTypeVM]) 
    
    const memoTable = useMemo(() => { 
        return ( 
        <HPHTable 
            id='tariff-code-table' 
            headerLabel={ChargeTypeMaintenanceConstant.TITLE} 
            isNewColumnSetting={true} 
            columns={INITIAL_CHARGE_TYPE_COL_DEF} 
            data={tableData??[]} 
            showPaginator={false} 
            editable={false} 
            showAddIcon={true} 
            onAddClick={handleAddClick} 
            showDeleteButton={false} 
            showReloadIcon={false} 
            isScrollHighlighted={true} 
            selectionMode={false} 
            isRowHighligted={true} 
            onSelectionChanged={handleSelectionChange} 
            onRowDoubleClick={(e: any, entity: ChargeTypeEntity) => handleRowDoubleClick(entity)} 
            gridHeight="customHeight" 
            customHeight="calc(100vh - 75px)"  
            ref={gridRef} 
            /> 
        ); 
    },[tableData, handleAddClick, handleSelectionChange, handleRowDoubleClick]) 
    return <><TableWrapper> 
            {memoTable} 
        </TableWrapper> 
        </>; 
} 
export default memo(ChargeTypeTablePanel); 
